body {
  margin: 0;
  font-family: $font-family-base, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
.nowrap {
  white-space: nowrap;
}
h1 {
  font-family: $serif;
  font-weight: bold;
  color: $blue;
  font-size: 69px;
  sup {
    font-family: $font-family-base;
    font-weight: 100;
    font-size: 16px;
    top: -20px;
  }
}
h2 {
  font-family: $serif;
  font-weight: bold;
  color: $blue;
  font-size: 43px;
}
.bold-italic {
  font-family: $serif;
  font-weight: bold;
  font-style: italic;
  font-size: 29px;
}
p {
  font-size: 23px;

  &.blue {
    color: $blue;
    font-family: $serif;
  }
}


div.wide {
  width: 100vw;
  max-width: 100%;
}
.contain {
  max-width: 1000px;
}
.fcontain {
  max-width: 1300px;
}

.x-logo {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; 
}

.btn {
  border: 0px solid transparent !important;
  box-shadow: none !important;
  border-color: transparent !important;
}
.border-tab {
  width: 255px;
  height: 2px;
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background-color: $blue;
}

.header {
  background-image: url(../assets/texture.jpg);
  background-position: center center;
  background-repeat: repeat;
  max-height: 198px;

  .main-logo {
    display: inline-block;
    position: relative;
    z-index: 1;
    max-width: 350px;
    @include media-breakpoint-down(lg) {
      max-width: 240px;
      top: -20px;
    }
    @include media-breakpoint-down(sm) {
      
    }
  }
  @include media-breakpoint-down(lg) {
    
  }

}

.top-button {
  background-color: $red !important;
  text-transform: uppercase;
  border: 0px solid transparent;
  outline: none !important;
  position: relative;
  top: -24px;
  color: $white !important;
  @extend .px-5;
  outline: none !important;
  font-weight: 300;
  font-size: 25px;
  font-family: $font-family-base;
}

.gscarousel {
  padding-top: 8px;

  .carousel-item {
    height: 440px;
    .img-1 {
      background-image: url(../assets/carousel_1.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .img-2 {
      background-image: url(../assets/carousel_2.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .img-3 {
      background-image: url(../assets/carousel_3.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

.mulberry-farms {
  background-color: $cream;
  
  svg {
    max-width: 100%;
  }
}
.card-container {
  
  padding-bottom: 8rem;

  .parent-cards {
    > .col {
      margin-top: 96px;
    }
  }

  .card {
    cursor: pointer;
    border: 0px solid transparent;

    .card-icon {
      height: 180px;
      width: 180px;
      position: absolute;
      top: -50px;
      left: 50%;
      transform: translate(-50%, 0);
      margin: 0;
  
      
      svg {
        width: 100%;
        height: 100%;
        overflow: visible;
      }
    }

    .img {
      height: auto;
      width: 100%;
      padding-bottom: 100%;
      background-size: cover;
      background-position: bottom left;
    }
    .card-body {
      background-color: $blue;
      
      .card-title {
        font-family: $serif;
        color: $cream;
        font-size: 38px;
        font-weight: bold;
        text-transform: capitalize;
        white-space: nowrap;

        sup {
          font-family: $font-family-base;
          font-weight: 100;
          font-size: 16px;
        }
      }
    }
  }
  .card-back {
    position: relative;
    width: 100%;
    height: 100%;

    
    .back-container {
      margin: 140px 0 0 0;

      p {
        color: $white;
        font-size: 23px; 
      }
    }
  }
  .card-front {

    .card-icon {
      z-index: -1;

      svg {
        path, line, rect {
          fill: $blue;
          color: $blue;
        }
      }
    }

  }
}

.video-container {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;

  video {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
}

.flag {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  max-width: 174px;
}

.siteplan {
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-color: $cream;


  .siteplan-image {
    position: relative;
    border: 8px solid $white;
    background-image: url(../assets/spmain.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $white;
    
    img.spmain {
      visibility: hidden;
    }

    img.sp {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      
      &.true {
        opacity: 1;
      }
    }
  }

  .spcards {
    .spcard {
      border: 2px solid $blue;
      color: $blue;
      cursor: pointer;
      position: relative;
      background-color: $cream;
      transition: color 0.3s ease-in-out;
      transition: background-color 0.3s ease-in-out;

      &:after {
        content: " ";
        width: 0;
        height: 0;
        top: 4px;
        right: 4px;
        position: absolute;
        border-style: solid;
        border-width: 0 30px 30px 0;
        border-color: transparent #912d37 transparent transparent;
      }

      &.true {
        color: $white;
        background-color: $blue;
        &:after {
          display: none !important;
        }
      }
      p {
        margin: 1rem 3rem;
      }
    }
  }
}

.footer {
  background-image: url(../assets/texture.jpg);
  background-position: center center;
  background-repeat: repeat;
  color: $white;
  font-family: $serif;

  .btn {
    @extend .px-5;
    outline: none !important;
    background-color: $blue !important;
    text-transform: uppercase;
    border: 0px solid transparent;
    font-weight: 300;
    font-size: 25px;
    color: $white;
    font-family: $font-family-base;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0;
    position: relative;

  }

  .border-col {
    @include media-breakpoint-up(lg) {
      border-right: 1px solid $cream;  
    }
    
  }

  p {
    font-size: 24px;
  }

  .footer-logo {
    svg {
      max-width: 242px;
    }
  }

  h3, .phone {
    font-size: 42px;
  }

  .phone {
    font-weight: bold;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    position: relative;
  }

  .address {
    font-weight: bold;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    position: relative;
  }
  .legal {
    font-size: 18px;
    text-align: center;
    span {
      display: inline-flex;
      margin-right: 0.5rem;
      &.f-logo {
        vertical-align: text-bottom;
      }
    }
    a {
      color: $white;
    }
  }
}

@include media-breakpoint-down(lg) {  
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 32px;
  }
  p {
    font-size: 18px;
  }
  .header {
    max-height: 148px;
  }
  .gscarousel {
    padding-top: 2px;
    .carousel-item {
      height: 150px;
    }
  }
  .card-container {
    .card-front {
      .card-body {
        .card-title.h5 {
          font-size: 32px;
        }   
      }  
    }
    .card-back.card {
      .card-body {
        .card-title.h5 {
          margin-top: 80px;
        }
        p {
          font-size: 20px;
        }
      }  
    }  
  }
}

@include media-breakpoint-down(md) {  
  .card-container {
    .card-front {
      .card-body {
        .card-title.h5 {
          font-size: 25px;
        }   
      }  
    }
    .card-back.card {
      .card-body {
        p {
          font-size: 25px;
        }   
      }
      min-height: 385px;
    }  
  }
}

@include media-breakpoint-up(xxl) {  
  .footer {
    .footer-logo {
      svg {
        max-width: 60%;
      }
    }

    h3, .phone {
      font-size: 55px;
    }
  }
}
