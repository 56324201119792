$serif: "mrs-eaves-xl-serif-narrow";
$font-family-base: "soleil";
$brown: #835E41;
$blue: #113657;
$cream: #F9F4EE;
$red: #912D37;
//3rd
@import '../../node_modules/bootstrap/scss/bootstrap';

@import 'main';
